import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button, ControlGroup, Input, ButtonSize, ButtonColor } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "control-group"
    }}>{`Control Group`}</h1>
    <p>{`각기 다른 컨트롤을 하나의 단위로 묶어서 보여줍니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={ControlGroup} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<ControlGroup>\n  <Input placeholder=\"Placeholder text\" />\n  <Button size={ButtonSize.LARGE} color={ButtonColor.ORANGE}>\n    Click me\n  </Button>\n</ControlGroup>\n<br />\n<ControlGroup>\n  <Input placeholder=\"Placeholder text\" size=\"sm\" />\n  <Button color={ButtonColor.ORANGE}>Click me</Button>\n</ControlGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      ControlGroup,
      Input,
      ButtonSize,
      ButtonColor,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ControlGroup mdxType="ControlGroup">
    <Input placeholder="Placeholder text" mdxType="Input" />
    <Button size={ButtonSize.LARGE} color={ButtonColor.ORANGE} mdxType="Button">
      Click me
    </Button>
  </ControlGroup>
  <br />
  <ControlGroup mdxType="ControlGroup">
    <Input placeholder="Placeholder text" size="sm" mdxType="Input" />
    <Button color={ButtonColor.ORANGE} mdxType="Button">Click me</Button>
  </ControlGroup>
    </Playground>
    <h2 {...{
      "id": "block-control-group"
    }}>{`Block control group`}</h2>
    <Playground __position={2} __code={'<ControlGroup fill>\n  <Input placeholder=\"Placeholder text\" />\n  <Button size={ButtonSize.LARGE} color={ButtonColor.ORANGE}>\n    Click me\n  </Button>\n</ControlGroup>\n<br />\n<ControlGroup fill>\n  <Input placeholder=\"Placeholder text\" size=\"sm\" />\n  <Button color={ButtonColor.ORANGE}>Click me</Button>\n</ControlGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      ControlGroup,
      Input,
      ButtonSize,
      ButtonColor,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ControlGroup fill mdxType="ControlGroup">
    <Input placeholder="Placeholder text" mdxType="Input" />
    <Button size={ButtonSize.LARGE} color={ButtonColor.ORANGE} mdxType="Button">
      Click me
    </Button>
  </ControlGroup>
  <br />
  <ControlGroup fill mdxType="ControlGroup">
    <Input placeholder="Placeholder text" size="sm" mdxType="Input" />
    <Button color={ButtonColor.ORANGE} mdxType="Button">Click me</Button>
  </ControlGroup>
    </Playground>
    <h2 {...{
      "id": "vertical-control-group"
    }}>{`Vertical control group`}</h2>
    <Playground __position={3} __code={'<ControlGroup vertical>\n  <Input placeholder=\"Placeholder text\" />\n  <Button size={ButtonSize.LARGE} color={ButtonColor.ORANGE}>\n    Click me\n  </Button>\n</ControlGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      ControlGroup,
      Input,
      ButtonSize,
      ButtonColor,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ControlGroup vertical mdxType="ControlGroup">
    <Input placeholder="Placeholder text" mdxType="Input" />
    <Button size={ButtonSize.LARGE} color={ButtonColor.ORANGE} mdxType="Button">
      Click me
    </Button>
  </ControlGroup>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      